<template>
  <div>
    <keywords-settings :selected="selected" @empty-selected="emptySelected" />
    <table-overview
      v-if="!showChild"
      title="Keywords"
      store="keywords"
      :headers="[
        { sortable: false },
        { text: 'Keyword', align: 'left', value: 'title' },
        {
          text: 'Locale',
          align: 'left',
          value: 'locale_code',
          sortable: false
        },
        { text: 'searches', value: `search_count` },
        { text: 'Content plan', sortable: false, value: `search_count` },
        { text: 'position', sortable: false },
        { text: 'url', value: 'url', sortable: false },
        { text: 'Edit', value: 'edit', sortable: false },
        { text: 'Details', value: 'details', sortable: false }
      ]"
      searchable
      force-initial-sort="search_count"
      descending
      expand
      select-all
      @set-selected="setSelected"
    >
      <keywords-table-row
        slot="table-row"
        slot-scope="{ item }"
        :keyword-id="item"
        :selected="isInSelected(item)"
        :selected-locale="selectedLocale"
        :locale-index="3"
        @selected="addToSelected"
        @un-selected="removeFromSelected"
      />
      <keywords-table-filter
        slot="filters"
        store="websites"
        @locale-selected="setLocale"
      />
    </table-overview>
    <router-view v-else />
  </div>
</template>
<script type="text/babel">
import TableOverview from "@/components/generic/overview/TableOverview";
import KeywordsTableRow from "@/components/keyword/KeywordTableRow";
import KeywordsTableFilter from "@/components/keyword/KeywordsOverviewFilters";
import store from "@/store";
import keywordsModule from "@/store/modules/keywords";
import { mapOverviewLifecycleMethods } from "../helpers";
import KeywordsSettings from "../../components/keyword/KeywordsSettings";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "keywords",
  keywordsModule
);

export default {
  name: "keywords-overview",
  components: {
    KeywordsSettings,
    TableOverview,
    KeywordsTableRow,
    KeywordsTableFilter
  },
  data: () => ({
    selectedLocale: 3,
    selected: [],
    priority: null,
    deleteDialog: false
  }),
  beforeRouteEnter: async (to, from, next) => {
    if (!store.state.keywords) {
      store.registerModule("keywords", keywordsModule);
    }
    next();
  },
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  },
  methods: {
    setLocale(val) {
      this.selectedLocale = val;
    },
    addToSelected(val) {
      this.selected.push(val);
    },
    removeFromSelected(val) {
      this.selected = this.selected.filter(function(value) {
        return value !== val;
      });
    },
    setSelected(val) {
      this.selected = val;
    },
    isInSelected(val) {
      return this.selected.includes(val);
    },
    emptySelected() {
      this.selected = [];
    }
  },
  ...overviewLifecycleMethods
};
</script>
