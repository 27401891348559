import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";

const fetchCall = api.keyword.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall });
  },
  async setPrioritySettings({ dispatch }, { keywordLocaleIds, priorityLevel }) {
    await api.keyword.setPrioritySettings({ keywordLocaleIds, priorityLevel });
    dispatch(
      "snackbar/addItem",
      {
        text: `${keywordLocaleIds.length} keyword locales where set to ${priorityLevel}`,
        color: "success"
      },
      { root: true }
    );
    dispatch(DISPATCH_FETCH);
  },
  async deleteKeywordLocales({ dispatch }, { keywordLocaleIds }) {
    await api.keyword.deleteKeywordLocales({ keywordLocaleIds });
    dispatch(
      "snackbar/addItem",
      {
        text: `${keywordLocaleIds.length} keyword locales where deleted`,
        color: "success"
      },
      { root: true }
    );
    dispatch(DISPATCH_FETCH);
  }
};
