var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_c('v-checkbox',{attrs:{"primary":"","hide-details":""},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})],1),_c('td',[_c('v-icon',{attrs:{"color":_vm.priorityColor}},[_vm._v("fad fa-bullseye")])],1),_c('td',{staticStyle:{"width":"30%"},on:{"click":function($event){_vm.showTopUrls = !_vm.showTopUrls}}},[_c('v-badge',{attrs:{"color":"#C0C0C0"}},[(_vm.keywordHasRemark())?_c('v-tooltip',{attrs:{"slot":"badge","bottom":""},slot:"badge"},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" i ")]),_vm._v(_vm._s(_vm.keyword.remark)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.keyword.title)+" ")],1)],1),_c('td',[_c('v-chip',{attrs:{"text-color":"white","color":_vm.localeColor,"small":"","label":""}},[_vm._v(_vm._s(_vm.keywordLocale.locale_code))])],1),_c('td',{staticStyle:{"width":"15%"},on:{"click":function($event){_vm.showTopUrls = !_vm.showTopUrls}}},[_vm._v(" "+_vm._s(_vm.keywordLocale.searchCount)+" ")]),_c('td',{staticStyle:{"width":"25%"}},[(_vm.keyword.contentPlan)?_c('router-link',{attrs:{"to":{
        name: 'content.content-plans.show',
        params: { id: _vm.keyword.contentPlan.id }
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.keyword.contentPlan.title)+" ")]):_vm._e()],1),_c('td',{staticStyle:{"width":"5%"},on:{"click":function($event){_vm.showTopUrls = !_vm.showTopUrls}}},[_vm._v(" "+_vm._s(_vm.keywordLocale.position)+" ")]),(_vm.showTopUrls === false)?_c('td',{staticStyle:{"width":"25%"},on:{"click":function($event){_vm.showTopUrls = !_vm.showTopUrls}}},[_vm._v(" "+_vm._s(_vm.bulditTopUrl(_vm.keywordLocale))+" ")]):_vm._e(),(_vm.showTopUrls === true)?_c('td',{staticStyle:{"width":"25%"}},[_vm._l((_vm.keywordLocale.payload),function(value,index){return [(value != null)?_c('ul',{key:index,staticStyle:{"list-style-type":"none"}},[_c('a',{attrs:{"href":value.url,"target":"_blank"}},[(_vm.isNoBulditLink(value, index))?_c('li',[_vm._v(" "+_vm._s(value.position)+". "+_vm._s(_vm.regexUrl(value.url))+" ")]):_vm._e()]),_c('a',{attrs:{"href":value.url,"target":"_blank"}},[(value.position === _vm.keywordLocale.position)?_c('li',{staticStyle:{"background-color":"#FFD817"}},[_vm._v(" "+_vm._s(value.position)+". "+_vm._s(_vm.regexUrl(value.url))+" ")]):_vm._e()]),_c('a',{attrs:{"href":value.url,"target":"_blank"}},[(_vm.isBulditLink(value, index))?_c('li',{staticStyle:{"background-color":"#A9A9A9"}},[_vm._v(" "+_vm._s(value.position)+". "+_vm._s(_vm.regexUrl(value.url))+" ")]):_vm._e()])]):_vm._e()]})],2):_vm._e(),_c('td',{staticStyle:{"width":"5%"}},[_c('v-menu',{attrs:{"bottom":"","left":""}},[_c('v-btn',{attrs:{"slot":"activator","icon":""},slot:"activator"},[_c('v-icon',[_vm._v("far fa-plus")])],1),_c('v-list',[_c('v-list-tile',{on:{"click":_vm.openRemarkDialog}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("far fa-search")])],1),_c('v-list-tile-title',[_vm._v("edit "+_vm._s(_vm.keyword.title))])],1),_c('v-list-tile',{attrs:{"to":{
            name: 'annotations.create',
            params: { keywordIds: _vm.keyword.id }
          }}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("far fa-search")])],1),_c('v-list-tile-title',[_vm._v("Annotate "+_vm._s(_vm.keyword.title))])],1)],1)],1)],1),_c('td',{staticStyle:{"width":"5%"}},[_c('v-btn',{attrs:{"icon":"","to":{
        name: 'content.keywords.show',
        params: { id: _vm.keyword.id }
      }}},[_c('v-icon',[_vm._v("info_outline")])],1)],1),_c('add-remark-dialog',{attrs:{"keyword":_vm.keyword,"keyword-locale":_vm.keywordLocale,"add-remark":_vm.addRemark}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }