<template>
  <v-autocomplete
    v-model="model"
    :items="priorityOptions"
    label="Priority Level"
  >
    <template v-slot:item="{ item }">
      <v-list-tile-avatar>
        <v-icon :color="getIconColorByPriority(item)">fad fa-bullseye</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>{{ item }}</v-list-tile-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
export default {
  name: "v-select-keyword-priority-level",
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    priorityOptions: ["INFORMATIVE", "IMPORTANT", "HIGH TARGET", "DEFAULT"]
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    getIconColorByPriority(type) {
      if (type === "IMPORTANT") {
        return "warning";
      } else if (type === "HIGH TARGET") {
        return "error";
      } else if (type === "INFORMATIVE") {
        return "blue";
      } else {
        return "grey";
      }
    }
  }
};
</script>
