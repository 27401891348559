<template>
  <v-form>
    <v-select-locale v-model="locales" multiple />
    <v-select-locale
      v-model="comparisonLocale"
      clearable
      label="Comparison locale"
    />
    <v-autocomplete-content-plan
      v-model="contentPlan"
      item-text="title"
      multiple
    />
    <v-autocomplete-tag
      v-model="tags"
      :type="'KEYWORD'"
      :initial-load="true"
      multiple
    />
    <v-select-keyword-priority-level v-model="priorityLevel" />
  </v-form>
</template>
<script type="text/babel">
import VSelectLocale from "@/components/locale/VSelectLocale";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteContentPlan from "@/components/content-plan/VAutocompleteContentPlan";
import VAutocompleteTag from "@/components/tag/VAutocompleteTag";
import { SET_FILTER } from "@/store/templates/table/mutation-types";
import { isNil } from "lodash";
import VSelectKeywordPriorityLevel from "@/components/keyword/VSelectKeywordPriorityLevel";

const { mapComputedFilters } = createNamespacedHelpers("keywords");

export default {
  name: "keywords-table-filters",
  components: {
    VSelectKeywordPriorityLevel,
    VAutocompleteTag,
    VAutocompleteContentPlan,
    VSelectLocale
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      tagFilters: {
        type: "keyword"
      }
    };
  },
  computed: {
    ...mapComputedFilters([
      "comparisonLocale",
      "locales",
      "contentPlan",
      "tags",
      "priorityLevel"
    ])
  },
  watch: {
    comparisonLocale(newVal) {
      if (!isNil(newVal)) {
        this.$store.commit(`keywords/${SET_FILTER}`, {
          prop: "locales",
          value: null
        });
      }
    },
    locales(newVal) {
      if (!isNil(newVal)) {
        this.$store.commit(`keywords/${SET_FILTER}`, {
          prop: "comparisonLocale",
          value: null
        });
      }
    }
  },
  methods: {
    test(val) {
      this.$emit("locale-selected", val);
    }
  }
};
</script>
