<template>
  <v-flex v-if="selected.length > 0" xs12>
    <v-card style="padding: 20px;">
      <v-layout justify-spaced-around class="settings-header">
        <v-layout style="padding: 0; max-width: 50%;" row>
          <v-flex xs8>
            <v-select-keyword-priority-level v-model="priority" />
          </v-flex>
          <v-flex xs1>
            <v-btn
              color="primary"
              :disabled="!hasRolesOrPermissions('edit_keyword_priority_level')"
              @click="savePrioritySettings"
              >SAVE</v-btn
            >
          </v-flex>
        </v-layout>
        <ul>
          <li>Informative: 2 updates / year</li>
          <li>Important: 4 updates /month</li>
          <li>High target: 1 update /week + extra</li>
          <li>
            Default: Updates are determined based on the number of searches
          </li>
        </ul>
        <ul>
          <li>searches &lt; 101: 1 update / month</li>
          <li>searches &lt; 1001: 2 updates /month</li>
          <li>searches &gt; 1001: 1 update /week</li>
        </ul>
      </v-layout>
      <v-subheader>Delete all selected keywords?</v-subheader>
      <v-btn
        color="warning"
        :disabled="!hasRolesOrPermissions('keyword_delete')"
        @click="deleteDialog = true"
        >DELETE</v-btn
      >
    </v-card>
    <delete-keywords-dialog
      :selected-keywords="selected"
      :delete-dialog="deleteDialog"
      @close="deleteDialog = false"
      @keywords-deleted="deletedKeywordLocales"
    />
  </v-flex>
</template>
<script type="text/babel">
import DeleteKeywordsDialog from "./DeleteKeywordsDialog";
import VSelectKeywordPriorityLevel from "./VSelectKeywordPriorityLevel";
export default {
  name: "keywords-settings",
  components: { VSelectKeywordPriorityLevel, DeleteKeywordsDialog },
  props: {
    selected: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    priority: null,
    deleteDialog: false
  }),
  methods: {
    async savePrioritySettings() {
      await this.$store.dispatch("keywords/setPrioritySettings", {
        keywordLocaleIds: this.selected,
        priorityLevel: this.priority
      });
      this.$emit("empty-selected");
    },
    deletedKeywordLocales() {
      this.$emit("empty-selected");
    },
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  }
};
</script>
<style scoped>
.settings-header {
  border-bottom: 0.5px solid grey;
  padding: 20px;
}
ul {
  list-style-type: circle;
}
</style>
