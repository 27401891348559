<template>
  <v-card>
    <v-dialog v-if="deleteDialog" v-model="internalDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Delete keywords
        </v-card-title>
        <v-card-text
          >You are about to delete {{ selectedKeywords.length }} keywords. Are
          you certain?</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="internalDialog = false">
            Nevermind
          </v-btn>
          <v-btn color="primary" flat @click.native="deleteKeywordLocales">
            I'm sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
export default {
  name: "delete-keywords-dialog",
  props: {
    deleteDialog: {
      type: Boolean,
      default: false
    },
    selectedKeywords: {
      type: Array,
      required: true
    }
  },
  computed: {
    internalDialog: {
      get() {
        return this.deleteDialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async deleteKeywordLocales() {
      await this.$store.dispatch("keywords/deleteKeywordLocales", {
        keywordLocaleIds: this.selectedKeywords
      });
      this.$emit("keywords-deleted");
      this.internalDialog = false;
    }
  }
};
</script>
