<template>
  <v-card color="white" height="100%">
    <v-toolbar color="light-blue" class="primary">
      <v-toolbar-title class="white--text">
        Keyword Details
      </v-toolbar-title>
    </v-toolbar>
    <template>
      <v-progress-linear
        color="accent"
        style="margin-top: 0px"
        :indeterminate="isLoading"
      />
    </template>
    <template v-if="isInit">
      <v-card-text>
        <v-breadcrumbs>
          <v-icon slot="divider">
            forward
          </v-icon>

          <v-breadcrumbs-item
            :to="{
              name: 'content.content-plans.show',
              params: { id: contentPlan.id }
            }"
            target="_blank"
          >
            {{ contentPlan.title }}
          </v-breadcrumbs-item>
          <v-breadcrumbs-item disabled>
            {{ subjectTopic.title }}
          </v-breadcrumbs-item>
          <v-breadcrumbs-item disabled>
            {{ keywordCluster.title }}
          </v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-data-table
          :headers="headers"
          :items="keywords"
          :pagination.sync="pagination"
          :rows-per-page-items="[20, 50, 100]"
          hide-actions
          must-sort
          item-key="id"
        >
          <keyword-details-row
            slot="items"
            slot-scope="props"
            :keyword="props.item"
            :selected-locale="selectedLocale"
          />
        </v-data-table>
      </v-card-text>
    </template>
  </v-card>
</template>
<script type="text/babel">
import KeywordDetailsRow from "./KeywordDetailsRow";

export default {
  name: "keyword-details-dialog",
  components: {
    KeywordDetailsRow
  },
  props: {
    details: {
      type: Boolean,
      default: true
    },
    selectedLocale: {
      type: Number
    }
  },
  data: () => ({
    isLoading: false,
    isInit: false,
    subjectTopicId: null,
    pagination: {
      sortBy: `locales[0].searchCount`,
      descending: true
    },
    keywordHasLocale: false,
    localeIndex: null
  }),
  computed: {
    keyword() {
      return this.$store.getters["entities/getEntity"]({
        name: "keyword",
        id: this.$attrs.id
      });
    },
    keywordCluster() {
      return this.$store.getters["entities/getEntity"]({
        name: "keywordCluster",
        id: this.keyword.keywordClusterId
      });
    },
    keywords() {
      return this.$store.getters["entities/getEntities"]({
        name: "keyword",
        ids: this.keywordCluster.keywords
      });
    },
    subjectTopic() {
      return this.$store.getters["entities/getEntity"]({
        name: "subjectTopic",
        id: this.subjectTopicId
      });
    },
    contentPlan() {
      return this.$store.getters["entities/getEntity"]({
        name: "contentPlan",
        id: this.keyword.contentPlanId
      });
    },
    isInitialized() {
      return this.$store.getters["entities/isInitialized"]({
        name: "contentPlan",
        id: this.keyword.keywordClusterId
      });
    },
    headers() {
      let headers = [
        { text: "Keywords", align: "left", value: "title", sortable: false },
        { text: "Tags", align: "left", value: "tags", sortable: false },
        { text: "searches", value: `locales[0].searchCount` },
        { text: "position", sortable: false },
        { text: "url", value: "url", sortable: false }
      ];
      return headers;
    }
  },
  watch: {
    details() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (this.isInit === false) {
        this.isLoading = true;
        await this.$store.dispatch("entities/fetchContentPlan", {
          id: this.keyword.contentPlanId
        });
        await this.$store.dispatch("entities/fetchKeywordCluster", {
          id: this.keyword.keywordClusterId,
          includes: ["keywords.locales", "keywords.tags"]
        });
        this.subjectTopicId = await this.$store.dispatch(
          "entities/fetchSubjectTopic",
          {
            includes: [],
            filters: {
              specificKeywordSubjectTopic: this.keyword.id
            }
          }
        );
        this.isInit = true;
        this.isLoading = false;
      }
    }
  }
};
</script>
