import tableState from "@/store/templates/table/state";

const customizedTableState = tableState();
customizedTableState.filters.locales = [3];

export default {
  ...customizedTableState,
  tag: {},
  keywordLocale: {},
  keyword: {}
};
