<template>
  <tr>
    <td>
      <v-checkbox v-model="isSelected" primary hide-details></v-checkbox>
    </td>
    <td>
      <v-icon :color="priorityColor">fad fa-bullseye</v-icon>
    </td>
    <td style="width: 30%;" @click="showTopUrls = !showTopUrls">
      <v-badge color="#C0C0C0">
        <v-tooltip v-if="keywordHasRemark()" slot="badge" bottom>
          <span slot="activator"> i </span>{{ keyword.remark }}
        </v-tooltip>
        {{ keyword.title }}
      </v-badge>
    </td>
    <td>
      <v-chip text-color="white" :color="localeColor" small label>{{
        keywordLocale.locale_code
      }}</v-chip>
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keywordLocale.searchCount }}
    </td>
    <td style="width: 25%;">
      <router-link
        v-if="keyword.contentPlan"
        :to="{
          name: 'content.content-plans.show',
          params: { id: keyword.contentPlan.id }
        }"
        target="_blank"
      >
        {{ keyword.contentPlan.title }}
      </router-link>
    </td>
    <td style="width: 5%;" @click="showTopUrls = !showTopUrls">
      {{ keywordLocale.position }}
    </td>
    <td
      v-if="showTopUrls === false"
      style="width: 25%;"
      @click="showTopUrls = !showTopUrls"
    >
      {{ bulditTopUrl(keywordLocale) }}
    </td>
    <td v-if="showTopUrls === true" style="width: 25%;">
      <template v-for="(value, index) in keywordLocale.payload">
        <ul v-if="value != null" :key="index" style="list-style-type: none;">
          <a :href="value.url" target="_blank">
            <li v-if="isNoBulditLink(value, index)">
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="value.position === keywordLocale.position"
              style="background-color: #FFD817"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="isBulditLink(value, index)"
              style="background-color: #A9A9A9"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
        </ul>
      </template>
    </td>
    <td style="width: 5%;">
      <v-menu bottom left>
        <v-btn slot="activator" icon>
          <v-icon>far fa-plus</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile @click="openRemarkDialog">
            <v-list-tile-avatar>
              <v-icon>far fa-search</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>edit {{ keyword.title }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{
              name: 'annotations.create',
              params: { keywordIds: keyword.id }
            }"
          >
            <v-list-tile-avatar>
              <v-icon>far fa-search</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>Annotate {{ keyword.title }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
    <td style="width: 5%;">
      <v-btn
        icon
        :to="{
          name: 'content.keywords.show',
          params: { id: keyword.id }
        }"
      >
        <v-icon>info_outline</v-icon>
      </v-btn>
    </td>
    <add-remark-dialog
      :keyword="keyword"
      :keyword-locale="keywordLocale"
      :add-remark="addRemark"
    />
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import AddRemarkDialog from "@/components/content-plan/AddRemarkDialog";
import EntityMixin from "@/components/legacy/generic/entity/EntityMixin";
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapKeywordGetters } = createNamespacedHelpers("keywords");

export default {
  name: "keyword-table-row",
  components: { AddRemarkDialog },
  mixins: [EntityMixin],
  props: {
    keywordId: {
      type: Number,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTopUrls: false,
      addRemark: false,
      details: false
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapKeywordGetters([FIND_BY_ID]),
    keywordLocale() {
      return this[FIND_BY_ID](this.keywordId);
    },
    keyword() {
      return this.$store.getters["keywords/keyword"](
        this.keywordLocale.keyword_id
      );
    },
    isSelected: {
      get() {
        return this.selected;
      },
      set(value) {
        if (value) {
          this.$emit("selected", this.keywordLocale.id);
        } else {
          this.$emit("un-selected", this.keywordLocale.id);
        }
      }
    },
    priorityColor() {
      if (this.keywordLocale.priorityLevel === "IMPORTANT") {
        return "warning";
      } else if (this.keywordLocale.priorityLevel === "HIGH TARGET") {
        return "error";
      } else if (this.keywordLocale.priorityLevel === "INFORMATIVE") {
        return "blue";
      } else {
        return "grey";
      }
    },
    localeColor() {
      if (this.keywordLocale.locale_code === "nl_be") {
        return "primary";
      } else if (this.keywordLocale.locale_code === "nl_nl") {
        return "green";
      } else if (this.keywordLocale.locale_code === "fr_be") {
        return "red";
      } else if (this.keywordLocale.locale_code === "fr_fr") {
        return "orange";
      } else if (this.keywordLocale.locale_code === "en_gb") {
        return "brown";
      } else if (this.keywordLocale.locale_code === "de_de") {
        return "purple";
      } else {
        return "grey";
      }
    }
  },
  // watch: {
  //   selected(newVal) {
  //   }
  // },
  created() {},
  methods: {
    openRemarkDialog() {
      this.addRemark = !this.addRemark;
    },
    openDetailsDialog() {
      this.details = !this.details;
    },
    isBulditLink(value, index) {
      if (
        value.isBuldit === true &&
        value.position !== this.keywordLocale.position &&
        index < 10
      ) {
        return true;
      }
    },
    isNoBulditLink(value, index) {
      if (
        value.isBuldit === false &&
        value.position !== this.keywordLocale.position &&
        index < 10
      ) {
        return true;
      }
    },
    keywordHasRemark() {
      if (this.keyword.remark !== "" && this.keyword.remark !== null) {
        return true;
      }
    },
    bulditTopUrl(locale) {
      if (locale.payload === null) {
        return;
      }
      const selectedPayloadRow = locale.payload[locale.position];
      if (isNil(selectedPayloadRow)) {
        return;
      }
      return locale.payload[locale.position - 1].url.replace(
        /^(https?:\/\/)?(www\.)?/,
        ""
      );
    },
    regexUrl(url) {
      if (!isNil(url)) {
        return url.replace(/^(https?:\/\/)?(www\.)?/, "");
      }
    }
  }
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
  overflow-x: hidden;
  white-space: nowrap;
  width: 18vw;
  text-overflow: ellipsis;
}

li {
  display: inline;
}
</style>
