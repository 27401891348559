var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('keywords-settings',{attrs:{"selected":_vm.selected},on:{"empty-selected":_vm.emptySelected}}),(!_vm.showChild)?_c('table-overview',{attrs:{"title":"Keywords","store":"keywords","headers":[
      { sortable: false },
      { text: 'Keyword', align: 'left', value: 'title' },
      {
        text: 'Locale',
        align: 'left',
        value: 'locale_code',
        sortable: false
      },
      { text: 'searches', value: "search_count" },
      { text: 'Content plan', sortable: false, value: "search_count" },
      { text: 'position', sortable: false },
      { text: 'url', value: 'url', sortable: false },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Details', value: 'details', sortable: false }
    ],"searchable":"","force-initial-sort":"search_count","descending":"","expand":"","select-all":""},on:{"set-selected":_vm.setSelected},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('keywords-table-row',{attrs:{"keyword-id":item,"selected":_vm.isInSelected(item),"selected-locale":_vm.selectedLocale,"locale-index":3},on:{"selected":_vm.addToSelected,"un-selected":_vm.removeFromSelected}})}}],null,false,4080045709)},[_c('keywords-table-filter',{attrs:{"slot":"filters","store":"websites"},on:{"locale-selected":_vm.setLocale},slot:"filters"})],1):_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }