<template>
  <tr v-if="keywordHasLocale">
    <td style="width: 25%;" @click="showTopUrls = !showTopUrls">
      <v-badge color="#C0C0C0">
        <v-tooltip v-if="keywordHasRemark()" slot="badge" bottom>
          <span slot="activator"> i </span>{{ keyword.remark }} </v-tooltip
        >{{ keyword.title }}
      </v-badge>
    </td>
    <td v-if="keyword.tags !== null" style="width: 10%;">
      <template v-for="(tag, index) in keyword.tags">
        <v-chip
          v-if="index < 2 && tag !== undefined"
          :key="index"
          :color="tag.color"
          label
          small
        >
          {{ tag.name }}
        </v-chip>
      </template>
      <v-tooltip v-if="keyword.tags.length > 2" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <template v-for="(tag, index) in keyword.tags">
            <v-chip
              v-if="tag !== undefined"
              :key="index"
              :color="tag.color"
              label
            >
              {{ tag.name }}
            </v-chip>
          </template>
        </span>
      </v-tooltip>
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keywordLocales[localeIndex].searchCount }}
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keywordLocales[localeIndex].position }}
    </td>
    <td
      v-if="showTopUrls === false"
      style="width: 30%;"
      @click="showTopUrls = !showTopUrls"
    >
      {{ bulditTopUrl(keyword) }}
    </td>
    <td v-if="showTopUrls === true" style="width: 25%;">
      <template v-for="(value, index) in keywordLocales[localeIndex].payload">
        <ul :key="index" v-if="value != null" style="list-style-type: none;">
          <a :href="value.url" target="_blank">
            <li v-if="isNoBulditLink(value, index)">
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="value.position === keywordLocales[localeIndex].position"
              style="background-color: #FFD817"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="isBulditLink(value, index)"
              style="background-color: #A9A9A9"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
        </ul>
      </template>
    </td>
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "keyword-details-row",
  props: {
    keyword: {
      type: Object,
      required: false
    },
    selectedLocale: {
      type: Number
    }
  },
  data() {
    return {
      showTopUrls: false,
      addRemark: false,
      keywordHasLocale: false,
      localeIndex: null,
      keywordLocales: null
    };
  },
  computed: {},
  mounted() {
    this.setLocales();
    this.hasLocale();
  },
  methods: {
    bulditTopUrl() {
      if (this.keywordLocales[this.localeIndex].payload === null) {
        return;
      }
      const selectedPayloadRow = this.keywordLocales[this.localeIndex].payload[
        this.keywordLocales[this.localeIndex].position
      ];
      if (isNil(selectedPayloadRow)) {
        return;
      }
      return this.keywordLocales[this.localeIndex].payload[
        this.keywordLocales[this.localeIndex].position - 1
      ].url.replace(/^(https?:\/\/)?(www\.)?/, "");
    },
    regexUrl(url) {
      if (!isNil(url)) {
        return url.replace(/^(https?:\/\/)?(www\.)?/, "");
      }
    },
    isBulditLink(value, index) {
      if (
        value.isBuldit === true &&
        value.position !== this.keywordLocales[this.localeIndex].position &&
        index < 10
      ) {
        return true;
      }
    },
    isNoBulditLink(value, index) {
      if (
        value.isBuldit === false &&
        value.position !== this.keywordLocales[this.localeIndex].position &&
        index < 10
      ) {
        return true;
      }
    },
    keywordHasRemark() {
      if (this.keyword.remark !== "" && this.keyword.remark !== null) {
        return true;
      }
    },
    setLocales() {
      this.keywordLocales = this.$store.getters["entities/getEntities"]({
        name: "keywordLocale",
        ids: this.keyword.locales
      });
    },
    hasLocale() {
      this.keywordHasLocale = false;
      this.keywordLocales.forEach((e, index) => {
        if (e.locale_id === this.selectedLocale) {
          this.localeIndex = index;
          this.keywordHasLocale = true;
        }
      });
      return this.keywordHasLocale;
    }
  }
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
  overflow-x: hidden;
  white-space: nowrap;
  width: 18vw;
  text-overflow: ellipsis;
}
li {
  display: inline;
}
</style>
